import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import {
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Toolbar,
  AppBar,
  Typography,
  Box,
  Button,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PeopleIcon from '@mui/icons-material/People';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import TrailerIcon from '@mui/icons-material/EmojiTransportation';
import TollIcon from '@mui/icons-material/LocalAtm';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import SecurityIcon from '@mui/icons-material/Security';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BusinessIcon from '@mui/icons-material/Business';
import Register from './Register';
import Login from './Login';
import PrivateRoute from './PrivateRoute';
import Loads from './loads/Loads';
import UploadExcel from './reports/UploadExcel';
import TotalTableOld from './reports/TotalTableOld';
import TruckTotalsTable from './reports/TruckTotalsTable';
import TotalHolder from './reports/TotalHolder';
import TruckTable from './trucks/TruckTable';
import TruckList from './trucks/TruckList';
import TruckDetails from './trucks/TruckDetails';
import TrailerTable from './trailers/TrailerTable';
import DriverTable from './drivers/DriverTable';
import CreateCompany from './manage/CreateCompany';
import UsersTable from './users/UsersTable';
import Team from './Team';
import PrivacyPolicy from './PrivacyPolicy';
import DriverHiring from './drivers/DriverHiring'; // Import Driver Hiring Component
import DriverApplications from './drivers/DriverApplications'; // Import DriverApplications Component
import DriverApplicationDetails from './drivers/DriverApplicationDetails'; // Import DriverApplications Component

const drawerWidth = 240;

const navigationItems = [
  { text: 'Our Team', icon: <GroupIcon />, path: '/team' },
  { text: 'Company', icon: <BusinessIcon />, path: '/company' },
  { text: 'Loads', icon: <LocalShippingIcon />, path: '/loads' },
  { text: 'Users', icon: <PeopleIcon />, path: '/users' },
  { text: 'Drivers', icon: <DirectionsCarIcon />, path: '/drivers' },
  { text: 'Driver Hiring', icon: <DirectionsCarIcon />, path: '/driver-hiring' }, // Driver Hiring
  { text: 'Driver Applications', icon: <PeopleIcon />, path: '/driver-applications' }, // Driver Applications
  { text: 'Admin', icon: <AdminPanelSettingsIcon />, path: '/admin' },
  { text: 'Trucks', icon: <DirectionsCarIcon />, path: '/trucks' },
  { text: 'Trailers', icon: <TrailerIcon />, path: '/trailers' },
  { text: 'Tolls', icon: <TollIcon />, path: '/tolls' },
  { text: 'Fuel', icon: <LocalGasStationIcon />, path: '/fuel' },
  { text: 'Insurance', icon: <SecurityIcon />, path: '/insurance' },
  { text: 'Accounting', icon: <LocalAtmIcon />, path: '/accounting' },
  { text: 'Reports', icon: <AssessmentIcon />, path: '/reports' },
  { text: 'Settings', icon: <SettingsIcon />, path: '/settings' },
  { text: 'Privacy Policy', icon: <SettingsIcon />, path: '/privacy-policy' },
];

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [mobileOpen, setMobileOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState('Dashboard');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    navigate('/login');
  };

  useEffect(() => {
    const currentRoute = navigationItems.find((item) => item.path === location.pathname);
    setCurrentTab(currentRoute ? currentRoute.text : 'Dashboard');
  }, [location]);

  const drawer = (
    <div>
      <Toolbar />
      <List>
        {navigationItems.map((item) => (
          <ListItem
            button
            key={item.text}
            onClick={() => {
              navigate(item.path);
              if (isMobile) setMobileOpen(false);
            }}
            selected={location.pathname === item.path}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {currentTab}
          </Typography>
          <Button color="inherit" onClick={handleLogout} sx={{ textTransform: 'none' }}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ display: 'flex', flexGrow: 1, width: '100%' }}>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant={isMobile ? 'temporary' : 'permanent'}
            open={isMobile ? mobileOpen : true}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: 'background.default',
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/team" element={<Team />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/loads" element={<Loads />} />
            <Route path="/manage" element={<CreateCompany />} />
            <Route path="/users" element={<UsersTable />} />
            <Route path="/driver-hiring" element={<DriverHiring />} />
            <Route path="/driver-applications" element={<DriverApplications />} /> {/* Driver Applications */}
            <Route path="/applications/details" element={<DriverApplicationDetails />} />
            <Route path="/trucks" element={<TruckList />} />
            <Route path="/truck/:id" element={<TruckDetails />} />
            {navigationItems.map((item) => (
              <Route
                key={item.text}
                path={item.path}
                element={<div>{item.text} Page</div>}
              />
            ))}
          </Routes>
        </Box>
      </Box>
      <Box
        component="footer"
        sx={{
          bgcolor: 'background.paper',
          py: 2,
          textAlign: 'center',
          mt: 'auto',
        }}
      >
        <Typography variant="body2" color="text.secondary">
          © {new Date().getFullYear()} PrimeTurbo. All rights reserved.
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <a href="/privacy-policy" style={{ textDecoration: 'none', color: 'inherit' }}>
            Privacy Policy
          </a>
        </Typography>
      </Box>
    </Box>
  );
}

export default App;
