import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    TextField,
    Button,
    FormControlLabel,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@mui/material';

function DriverApplications() {
    const [applications, setApplications] = useState([]);
    const [experienceFilter, setExperienceFilter] = useState("");
    const [trailerTypeFilter, setTrailerTypeFilter] = useState("");
    const [showHidden, setShowHidden] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const getToken = () => localStorage.getItem("token");

    useEffect(() => {
        fetchApplications();
    }, [experienceFilter, trailerTypeFilter, showHidden]);

    const fetchApplications = async () => {
        setLoading(true);
        try {
            const token = getToken();
            const response = await fetch(
                `/api/applications?experience=${experienceFilter}&trailer_type=${trailerTypeFilter}&show_hidden=${showHidden}`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();

            console.log("Received data:", data);

            if (Array.isArray(data)) {
                setApplications(data);
            } else {
                console.error("Unexpected data format:", data);
                setApplications([]);
            }
        } catch (error) {
            console.error("Error fetching applications:", error);
            setApplications([]);
        } finally {
            setLoading(false);
        }
    };

    const handleRowClick = (app) => {
        navigate('/applications/details', { state: { application: app } });
    };

    const toggleHide = async (id) => {
        try {
            const token = getToken();
            await fetch(`/api/applications/toggle-hide?id=${id}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            fetchApplications();
        } catch (error) {
            console.error("Error toggling hide:", error);
        }
    };

    return (
        <div>
            <div style={{ marginBottom: '20px', display: 'flex', gap: '10px' }}>
                <TextField
                    label="Experience (Years)"
                    type="number"
                    value={experienceFilter}
                    onChange={(e) => setExperienceFilter(e.target.value)}
                />
                <FormControl style={{ minWidth: 120 }}>
                    <InputLabel>Trailer Type</InputLabel>
                    <Select
                        value={trailerTypeFilter}
                        onChange={(e) => setTrailerTypeFilter(e.target.value)}
                    >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="dryVan">Dry Van</MenuItem>
                        <MenuItem value="reefer">Reefer</MenuItem>
                        <MenuItem value="flatbed">Flatbed</MenuItem>
                        <MenuItem value="RGN">RGN</MenuItem>
                    </Select>
                </FormControl>
                <FormControlLabel
                    control={<Checkbox checked={showHidden} onChange={(e) => setShowHidden(e.target.checked)} />}
                    label="Show Hidden Applications"
                />
            </div>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Experience</TableCell>
                            <TableCell>CDL</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    Loading applications...
                                </TableCell>
                            </TableRow>
                        ) : applications && applications.length > 0 ? (
                            applications.map((app) => (
                                <TableRow
                                    key={app.id}
                                    onClick={() => handleRowClick(app)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <TableCell>{`${app.firstName} ${app.lastName}`}</TableCell>
                                    <TableCell>{app.phone}</TableCell>
                                    <TableCell>{app.experience} year(s)</TableCell>
                                    <TableCell>
                                        <img
                                            src={app.cdlFile}
                                            alt="CDL"
                                            width="100"
                                            style={{ objectFit: 'contain' }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={(e) => {
                                            e.stopPropagation(); // Prevent row click
                                            toggleHide(app.id);
                                        }}>
                                            {app.hide ? "Unhide" : "Hide"}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    No applications found.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default DriverApplications;
