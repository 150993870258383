import React from 'react';
import { useLocation } from 'react-router-dom';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Box,
    List,
    ListItem,
    ListItemText,
    Link,
} from '@mui/material';

function DriverApplicationDetails() {
    const { state } = useLocation();
    const application = state?.application;

    if (!application) {
        return <Typography variant="h6" color="error">No application details available.</Typography>;
    }

    return (
        <Box p={4}>
            {application.cdlFile && (
                <Card sx={{ mb: 4 }}>
                    <CardMedia
                        component="img"
                        height="300"
                        image={application.cdlFile}
                        alt="CDL Photo"
                        sx={{ objectFit: 'contain' }}
                    />
                </Card>
            )}

            <Typography variant="h4" gutterBottom>
                {`${application.firstName} ${application.lastName}`}
            </Typography>
            <Typography variant="h6" gutterBottom>
                Application ID: {application.ID}
            </Typography>

            <Grid container spacing={2}>
                {/* Personal Information */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Personal Information</Typography>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Email" secondary={application.email} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Phone" secondary={application.phone} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Birth Date" secondary={application.birthDate} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="SSN" secondary={application.ssn} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Address" secondary={`${application.address}, ${application.city}, ${application.state}, ${application.postalCode}`} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Lived at Current Address < 3 Years"
                                        secondary={application.livedAtCurrentAddressLessThan3Years ? "Yes" : "No"}
                                    />
                                </ListItem>
                            </List>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Employment Information */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Employment Information</Typography>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Applying For" secondary={application.applyingFor} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Company Name" secondary={application.companyName} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Company Address" secondary={application.companyAddress} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Company Phone" secondary={application.companyPhone} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Employment Start Year" secondary={application.employmentStartYear} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Employment End Year" secondary={application.employmentEndYear} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Worked at Current Company < 10 Years"
                                        secondary={application.workedAtCurrentCompanyLessThan10Years ? "Yes" : "No"}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Previous Employment" secondary={application.previousCompanies} />
                                </ListItem>
                            </List>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Vehicle and Certifications */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Vehicle Types and Certifications</Typography>
                            <List>
                                <ListItem>
                                    <ListItemText
                                        primary="Vehicle Types"
                                        secondary={Object.entries({
                                            "Dry Van": application.dryVan,
                                            Reefer: application.reefer,
                                            Flatbed: application.flatbed,
                                            RGN: application.RGN,
                                        })
                                            .filter(([_, value]) => value) // Only include types that are true
                                            .map(([key]) => key) // Extract the trailer type name
                                            .join(", ") || "None"} // Join them with commas, or show "None" if none are true
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="CDL Expiration" secondary={application.cdlExpiration} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Medical Card Expiration" secondary={application.medicalCardExpiration} />
                                </ListItem>
                            </List>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Files */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Files</Typography>
                            <List>
                                {application.previousCdlFile && (
                                    <ListItem>
                                        <Link href={application.previousCdlFile} target="_blank" rel="noopener">
                                            Download Previous CDL
                                        </Link>
                                    </ListItem>
                                )}
                                {application.medicalCardFile && (
                                    <ListItem>
                                        <Link href={application.medicalCardFile} target="_blank" rel="noopener">
                                            Download Medical Card
                                        </Link>
                                    </ListItem>
                                )}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Additional Information */}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Additional Information</Typography>
                            <Typography variant="body1">{application.comments}</Typography>
                            <Typography variant="body2">Hide: {application.hide ? "Yes" : "No"}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}

export default DriverApplicationDetails;
