import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Paper,
    Typography,
    CircularProgress,
    Alert,
} from '@mui/material';

// Utility function to convert mileage from meters to miles
const convertMileageToMiles = (mileage) => (mileage * 0.00062137).toFixed(2);

// Utility function to calculate the difference in mileage
const calculateNextServiceIn = (currentMileage, nextServiceMileage) => {
    if (!nextServiceMileage) return 'N/A';
    const difference = (nextServiceMileage - currentMileage) * 0.00062137; // Convert meters to miles
    return difference > 0 ? difference.toFixed(2) : 'Due';
};

const TruckList = () => {
    const [trucks, setTrucks] = useState([]); // State to hold the truck data
    const [loading, setLoading] = useState(true); // State to handle loading
    const [error, setError] = useState(null); // State to handle errors
    const [sortConfig, setSortConfig] = useState({ key: 'truck_id', direction: 'asc' }); // State for sorting configuration
    const navigate = useNavigate();

    // Fetch truck data from the API
    useEffect(() => {
        const fetchTrucks = async () => {
            const token = localStorage.getItem("token"); // Get token from localStorage

            try {
                const response = await axios.get('/api/trucks', {
                    headers: {
                        Authorization: `Bearer ${token}`, // Add the token to the Authorization header
                    },
                });

                const updatedTrucks = response.data.map((truck) => {
                    const nextService = truck.maintenance_history
                        .filter((entry) => !entry.serviced) // Find entries where `serviced` is false
                        .sort((a, b) => a.mileage - b.mileage)[0]; // Get the one with the lowest mileage

                    return {
                        ...truck,
                        next_service_mileage: nextService ? nextService.mileage : null, // Add `next_service_mileage`
                    };
                });

                setTrucks(updatedTrucks);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching truck data:', err);
                setError('Failed to fetch truck data');
                setLoading(false);
            }
        };

        fetchTrucks();
    }, []);

    // Handle click to navigate to the truck details page
    const handleTruckClick = (truck) => {
        navigate(`/truck/${truck.id}`, { state: { truck } });
    };

    // Sorting logic
    const sortedTrucks = [...trucks].sort((a, b) => {
        const { key, direction } = sortConfig;
        let aValue = a[key];
        let bValue = b[key];

        if (key === 'mileage' || key === 'next_service_mileage') {
            aValue = parseFloat(aValue || 0);
            bValue = parseFloat(bValue || 0);
        } else if (key === 'ecu_errors') {
            aValue = a.ecu_errors.length;
            bValue = b.ecu_errors.length;
        } else {
            aValue = aValue || '';
            bValue = bValue || '';
        }

        if (aValue < bValue) return direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return direction === 'asc' ? 1 : -1;
        return 0;
    });

    // Handle sorting updates
    const handleSort = (key) => {
        setSortConfig((prev) => ({
            key,
            direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    if (loading) {
        return (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <CircularProgress />
                <Typography variant="h6">Loading...</Typography>
            </div>
        );
    }

    if (error) {
        return (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <Alert severity="error">{error}</Alert>
            </div>
        );
    }

    return (
        <div style={{ padding: '20px' }}>
            <Typography variant="h4" gutterBottom>
                Trucks
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.key === 'truck_id'}
                                    direction={sortConfig.key === 'truck_id' ? sortConfig.direction : 'asc'}
                                    onClick={() => handleSort('truck_id')}
                                >
                                    <strong>Truck ID</strong>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.key === 'mileage'}
                                    direction={sortConfig.key === 'mileage' ? sortConfig.direction : 'asc'}
                                    onClick={() => handleSort('mileage')}
                                >
                                    <strong>Mileage (Miles)</strong>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell><strong>Location Name</strong></TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.key === 'ecu_errors'}
                                    direction={sortConfig.key === 'ecu_errors' ? sortConfig.direction : 'asc'}
                                    onClick={() => handleSort('ecu_errors')}
                                >
                                    <strong>Number of ECU Errors</strong>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={sortConfig.key === 'next_service_mileage'}
                                    direction={sortConfig.key === 'next_service_mileage' ? sortConfig.direction : 'asc'}
                                    onClick={() => handleSort('next_service_mileage')}
                                >
                                    <strong>Next Service In (Miles)</strong>
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedTrucks.map((truck) => (
                            <TableRow
                                key={truck.id}
                                hover
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleTruckClick(truck)}
                            >
                                <TableCell>{truck.truck_id}</TableCell>
                                <TableCell>{convertMileageToMiles(truck.mileage)}</TableCell>
                                <TableCell>{truck.location_name || 'N/A'}</TableCell>
                                <TableCell>{truck.ecu_errors.length}</TableCell>
                                <TableCell>
                                    {calculateNextServiceIn(truck.mileage, truck.next_service_mileage)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default TruckList;
