import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Box,
    Collapse,
    IconButton,
    Modal,
    TextField,
    Alert,
    CircularProgress,
    Checkbox,
} from '@mui/material';
import { ExpandMore, ExpandLess, AddCircleOutline } from '@mui/icons-material';
import axios from 'axios';

const TruckDetails = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const { truck } = state || {};

    const [showHistory, setShowHistory] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [description, setDescription] = useState('');
    const [mileage, setMileage] = useState((truck?.mileage * 0.00062137).toFixed(2));
    const [nextServices, setNextServices] = useState([]);
    const [serviceHistory, setServiceHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (truck?.maintenance_history) {
            const nextServicesData = truck.maintenance_history.filter((entry) => !entry.serviced)
                .sort((a, b) => a.mileage - b.mileage);

            const serviceHistoryData = truck.maintenance_history.filter((entry) => entry.serviced)
                .sort((a, b) => b.service_mileage - a.service_mileage);

            setNextServices(nextServicesData);
            setServiceHistory(serviceHistoryData);
        }
    }, [truck]);

    const handleModalOpen = () => setOpenModal(true);
    const handleModalClose = () => {
        setOpenModal(false);
        setDescription('');
        setMileage((truck?.mileage * 0.00062137).toFixed(2));
        setError(null);
    };

    const handleAddService = async () => {
        setLoading(true);

        const token = localStorage.getItem("token"); // Get token from localStorage
        const mileageInMeters = Math.round(parseFloat(mileage) / 0.00062137); // Convert mileage back to meters

        try {
            const response = await axios.post(
                '/api/maintenance',
                {
                    truck: truck.id,
                    description,
                    mileage: mileageInMeters,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // Add the token to the request headers
                    },
                }
            );

            const newService = {
                id: response.data.id,
                truck: truck.id,
                description,
                mileage: mileageInMeters,
                serviced: false,
            };

            setNextServices((prev) => [...prev, newService].sort((a, b) => a.mileage - b.mileage));
            setLoading(false);
            handleModalClose();
        } catch (err) {
            console.error('Error scheduling service:', err);
            setError('Failed to schedule service');
            setLoading(false);
        }
    };

    const handleMarkAsDone = async (service) => {
        const token = localStorage.getItem("token"); // Get token from localStorage
        const currentMileageInMeters = truck.mileage;

        try {
            await axios.patch(
                `/api/maintenance/${service.id}`,
                {
                    serviced: true,
                    service_mileage: currentMileageInMeters,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // Add the token to the request headers
                    },
                }
            );

            setNextServices((prev) => prev.filter((s) => s.id !== service.id));
            setServiceHistory((prev) => [
                {
                    ...service,
                    serviced: true,
                    service_mileage: currentMileageInMeters,
                },
                ...prev,
            ]);
        } catch (err) {
            console.error('Error updating service:', err);
            setError('Failed to update service');
        }
    };

    if (!truck) {
        return (
            <Box textAlign="center" mt={5}>
                <Typography variant="h5" color="error">
                    Truck not found
                </Typography>
            </Box>
        );
    }

    const ecuErrors = truck.ecu_errors || [];

    return (
        <div style={{ padding: '20px' }}>
            <Typography variant="h4" gutterBottom>
                Truck: {truck.truck_id}
            </Typography>
            <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                Back
            </Button>

            <Box mt={2}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell><strong>Truck</strong></TableCell>
                                <TableCell>{truck.truck_id}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><strong>Mileage (Miles)</strong></TableCell>
                                <TableCell>{(truck.mileage * 0.00062137).toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><strong>Location Name</strong></TableCell>
                                <TableCell>{truck.location_name || 'N/A'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><strong>Location Time</strong></TableCell>
                                <TableCell>{truck.location_time || 'N/A'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><strong>Rented</strong></TableCell>
                                <TableCell>{truck.rented ? 'Yes' : 'No'}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Typography variant="h5" gutterBottom style={{ marginTop: '20px' }}>
                Next Service
            </Typography>
            {nextServices.length === 0 ? (
                <Typography>No upcoming services.</Typography>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            {nextServices.map((entry) => (
                                <TableRow key={entry.id}>
                                    <TableCell>
                                        <Checkbox
                                            color="primary"
                                            onChange={() => handleMarkAsDone(entry)}
                                        />
                                    </TableCell>
                                    <TableCell><strong>Description:</strong> {entry.description}</TableCell>
                                    <TableCell>
                                        <strong>Mileage:</strong> {(entry.mileage * 0.00062137).toFixed(2)}
                                    </TableCell>
                                    <TableCell>
                                        <strong>Mileage:</strong> {entry.id}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <Box
                display="flex"
                alignItems="center"
                mt={2}
                onClick={handleModalOpen}
                style={{ cursor: 'pointer' }}
            >
                <AddCircleOutline color="primary" />
                <Typography variant="body1" ml={1}>
                    Schedule Service
                </Typography>
            </Box>

            <Box mt={2}>
                <Typography variant="h5" display="inline">
                    Service History
                </Typography>
                <IconButton
                    onClick={() => setShowHistory((prev) => !prev)}
                    aria-label="expand service history"
                >
                    {showHistory ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </Box>

            <Collapse in={showHistory}>
                {serviceHistory.length === 0 ? (
                    <Typography>No service history available.</Typography>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                {serviceHistory.map((entry) => (
                                    <TableRow key={entry.id}>
                                        <TableCell><strong>Description:</strong> {entry.description}</TableCell>
                                        <TableCell>
                                            <strong>Service Mileage:</strong> {(entry.service_mileage * 0.00062137).toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Collapse>

            <Typography variant="h5" gutterBottom style={{ marginTop: '20px' }}>
                ECU Errors
            </Typography>
            {ecuErrors.length === 0 ? (
                <Typography>No ECU errors found.</Typography>
            ) : (
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            {ecuErrors.map((error, index) => (
                                <TableRow key={index}>
                                    <TableCell><strong>FMI Description:</strong> {error.fmi_description}</TableCell>
                                    <TableCell><strong>SPN Description:</strong> {error.spn_description}</TableCell>
                                    <TableCell><strong>Occurrence:</strong> {error.occurrence}</TableCell>
                                    <TableCell><strong>Error Time:</strong> {error.error_time}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {/* Modal for scheduling service */}
            <Modal open={openModal} onClose={handleModalClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: '8px',
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        Schedule Service
                    </Typography>
                    {error && <Alert severity="error">{error}</Alert>}
                    <TextField
                        label="Description"
                        fullWidth
                        margin="normal"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <TextField
                        label="Mileage (Miles)"
                        type="number"
                        fullWidth
                        margin="normal"
                        value={mileage}
                        onChange={(e) => setMileage(e.target.value)}
                    />
                    <Box mt={2} display="flex" justifyContent="space-between">
                        <Button onClick={handleModalClose} color="secondary">
                            Cancel
                        </Button>
                        <Button
                            onClick={handleAddService}
                            color="primary"
                            variant="contained"
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Add'}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default TruckDetails;
